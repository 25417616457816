import { updateApiData } from "../../utils/utils"

export const getLoginRequestDetails = async (appInfo,values) => {
    const api = "account/request/verify"
    const data ={
        token:values?.token,
        expires_in:values?.expiresIn
    }
    const location = {
        path:"common",
        values:data
    }
    return await updateApiData(appInfo,api,location)
}
export const confirmLogin = async (appInfo,id) => {
    const api = "account/request/confirm "
    const data ={
        id
    }
    const location = {
        path:"common",
        values:data
    }
    return await updateApiData(appInfo,api,location)
}