import { createSlice } from "@reduxjs/toolkit";

export const ATokenSlice=createSlice({
    name:"accessToken",
    initialState:{
        value:""
    },
    reducers:{
        getAccessToken:(state,action)=>{
            state.value=action?.payload?.accessToken
        }
    }
})

export const {getAccessToken}=ATokenSlice.actions;
export default ATokenSlice.reducer