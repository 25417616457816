import { createSlice } from "@reduxjs/toolkit";

export const moreInfoOtherSlice=createSlice({
    name:"moreInfoOther",
    initialState:{
        value:{
            isModal:false,
            showId:null
        }
    },
    reducers:{
        getMoreInfoOther:(state,action)=>{
            state.value=action?.payload?.moreInfoOther
        }
    }
})

export const {getMoreInfoOther}=moreInfoOtherSlice.actions;
export default moreInfoOtherSlice.reducer