import {     fetchApiData,fetchApiDataV2, updateApiDataV2 } from "../../utils/utils"

export const getShowDetails=async (appInfo,showId)=>{
    const api=`show/${showId}`
    return await fetchApiData(appInfo,api)
}
export const getSimilarVideos=async (appInfo,showId)=>{
    const api=`show/similar/${showId}`
    return await fetchApiData(appInfo,api)
}
export const getB2BDetails=async (appInfo,url)=>{
    const api=`b2b/${url}`
    return await fetchApiData(appInfo,api)
}
export const downloadPlaylist =async (appInfo,b2b_id,video_id,name)=>{
    
    const api=`b2b/download`
    let params;
    if(name === 'trailerFlag'){
       params = {
        b2b_id:b2b_id,
        show_id:video_id
      }
    }else if(name === 'videoFlag'){
      params = {
        b2b_id:b2b_id,
        video_id:video_id
      }
    }else{
      params = {
        b2b_id:b2b_id,
        trailer_id:video_id
      }
    }
    
    return await fetchApiData(appInfo,api,params)
}
export const updateB2BViewCOunt=async (appInfo,id)=>{
    const api = "b2b/updateCount";
    const data = {id};
    const location = {
      path:"common",
      values:data
  }
    return await updateApiDataV2(appInfo, api, location);    
  }
  export const getVideoDetails = async (id,appInfo,b2b_id) =>{
    const api =`video/${id}?b2b_id=${b2b_id}`
    return await fetchApiDataV2(appInfo,api)
}