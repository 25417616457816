import {fetchApiData,updateApiData} from "../../../utils/utils"

export const forgotPasswordEmailVerify = async (appInfo, value) => {
  const api = "account/passwordReset";
  const params = {
    user_email:value
  }
  return await fetchApiData(appInfo, api, params);
};

export const resetPassword  = async (appInfo, values) => {
  const api = "account/password/change";
  const data = {
    password: values?.password,
    token: values?.token
  };
  const location = {
    path:"common",
    values:data
}
  return await updateApiData(appInfo, api, location);
};