import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as service from "./service";
import { ToastContainer, toast } from "react-toastify";

const ChangePassword = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const submitHandler = (e) => {
    e.preventDefault();
    if(validation()===true){
        updatePassword();
    }else{
        toast.error("Check your fields!", {
            position: "bottom-center",
          });
    }
  };

  const validation = () => {
    let validationStatus = true;
    let errors = {};
    let passwordRegex =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;

    const {newPassword,currentPassword,confirmPassword} = values
      if (!currentPassword) {
        errors.currentPassword = "Current Password is required!";
        validationStatus = false
      }

    if (!newPassword) {
      errors.newPassword = "Password is required!";
      validationStatus = false
    }else if(!newPassword?.match(passwordRegex)){
      errors.newPassword = "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special characters!";
        validationStatus = false
    }

    if (!confirmPassword) {
        errors.confirmPassword = "Confirm Password is required!";
        validationStatus = false
      }else if(confirmPassword !== newPassword){
        errors.confirmPassword = "Password not match!";
          validationStatus = false
      }
      setErrors(errors)
      return validationStatus;

  };
  const updatePassword = async () => {
    try {
      const response = await service.updatePassword(appInfo, values);

      if (response?.data?.success === true) {
        toast.success("Password changed successfully", {
          position: "bottom-center",
        });
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }


  };

  const handleUpdate = (item) => {
    const { name, value } = item?.target;
    handleChange(name, value);
  };

  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <Fragment>
      <div className="ChangePassword">
        <ToastContainer />

        <div className="wrapper">
          <h1 className="heading">Change Password</h1>
          <div className="contents">
            <div className="formSection">
              <form>
                <div className="inputContainer">
                  <label htmlFor="#cpaasword">Current Password</label>
                  <input
                    type="password"
                    name="currentPassword"
                    id="cpaasword"
                    value={values?.currentPassword || ""}
                    onChange={(item) => handleUpdate(item)}
                  />
                  {errors?.currentPassword && (
                    <span className="error">{errors?.currentPassword}</span>
                  )}
                </div>
                <div className="inputContainer">
                  <label htmlFor="#newpassword">New Password</label>
                  <input
                    type="password"
                    name="newPassword"
                    id="newpassword"
                    value={values?.newPassword || ""}
                    onChange={(item) => handleUpdate(item)}
                  />
                  {errors?.newPassword && (
                    <span className="error">{errors?.newPassword}</span>
                  )}
                </div>
                <div className="inputContainer">
                  <label htmlFor="#confirmpassword">Confirm Password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmpassword"
                    value={values?.confirmPassword || ""}
                    onChange={(item) => handleUpdate(item)}
                  />
                  {errors?.confirmPassword && (
                    <span className="error">{errors?.confirmPassword}</span>
                  )}
                </div>

                <div
                  className="buttonContainer"
                  onClick={(e) => submitHandler(e)}
                >
                  <div className="background"></div>
                  <button type="submit" className="bg-button">
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChangePassword;
