import { createSlice } from "@reduxjs/toolkit";

export const DownloadB2bSlice=createSlice({
    name:"downloadB2bModal",
    initialState:{
        value:{
            showDownload:false,
            downloadProgress:null,
            filename:null
        }
    },
    reducers:{
        getDownloadProgress:(state,action)=>{
            state.value=action?.payload?.downloadB2bModal
        }
    }
})

export const {getDownloadProgress} = DownloadB2bSlice.actions
export default DownloadB2bSlice.reducer