import {updateApiData } from "../../utils/utils";

export const getTVCode = async (appInfo) => {
    const api = "user/code/generate";
    return updateApiData(appInfo,api)
}

export const loginWithTVCode = async (appInfo,code) => {
    const api = "user/code/enter";
    const location = {
        path:"common",
        values:{code}
    }
    return updateApiData(appInfo,api,location)
}