import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { affliateAnalytics } from '../../network/service';

const AffliatePage = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const [redirectUrl,setRedirectUrl] = useState("") 
  const appInfo = {
    projectDetails: projectInfo,
  }; 
  const redirectPath = localStorage.getItem("affliateRedirectPath")
  useEffect(()=>{
    updateAffliateAnalytics()
    setRedirectUrl(window.location.origin+redirectPath)
    return ()=> resetValues()
    },[])
  const webButtonHandler=()=>{
    resetValues()
    window.open(redirectUrl,"_self")
  }

  const updateAffliateAnalytics = async () =>{
    try{
      const response = await affliateAnalytics(appInfo);
      if(response.status===200){
          localStorage.removeItem("affliateUrl");
      }
    }catch(err){

    }
  }

  const resetValues = () => {
    localStorage.removeItem("affliateId");
    localStorage.removeItem("affliateRedirectPath");
    localStorage.removeItem("affliateUrl");
  }

  const openAppHandler = () => {
    resetValues()
    window.location.href = projectInfo?.projectConfig?.config?.APPLINK_URL + redirectPath
    
  }
  return (
    <div className='affliatePage'>
        <div className="top">
            <div className="logoSection">
                <h1 className='image'>
                    <img src={projectInfo?.projectConfig?.config?.LOGO} alt="Logo" />
                </h1>
            </div>
            <h1>{projectInfo?.projectConfig?.config?.TAB_TITLE}</h1>
        </div>
        <div className="bottom">
            <div className="buttons">
                <div className="buttonContainer">
                    <button className="useApp" onClick={openAppHandler}>Use App</button>
                </div>
                <div className="buttonContainer">
                    <button className="web" onClick={webButtonHandler}>Continue as Web</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AffliatePage