import { createSlice } from "@reduxjs/toolkit";

export const UserSlice=createSlice({
    name:"user",
    initialState:{
        value:""
    },
    reducers:{
        getUser:(state,action)=>{
            state.value=action?.payload?.user
        

        }
    }
})

export const {getUser}=UserSlice.actions
export default UserSlice.reducer