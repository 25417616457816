import { createSlice } from "@reduxjs/toolkit";

export const deviceSubscriptionSlice=createSlice({
    name:"deviceSubscription",
    initialState:{
        value:{}
    },
    reducers:{
        getDeviceSubscription:(state,action)=>{
            state.value=action?.payload?.deviceSubscription
        }
    }
})

export const {getDeviceSubscription}=deviceSubscriptionSlice.actions
export default deviceSubscriptionSlice.reducer