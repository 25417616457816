import { fetchApiData } from "../../utils/utils";

export const getNews= async (appInfo) => {
    const api = `news/list`;
    return fetchApiData(appInfo,api)
}

export const getNewsDetails= async (appInfo,newsId) => {
    const api = `news/${newsId}`;
    return fetchApiData(appInfo,api)
}