import { createSlice } from '@reduxjs/toolkit';


const tvCodeSlice = createSlice({
  name: 'tv',
  initialState:{
    tvCode:"",
    subscriptionPageSkip:false,
},
  reducers: {
    setTvCode: (state, action) => {
        state.tvCode = action.payload.value;
    },
    setSubscriptionPageSkip: (state, action) => {
        state.subscriptionPageSkip = action.payload.value;
    }
  },
});

export const { setTvCode, setSubscriptionPageSkip } = tvCodeSlice.actions; 

export default tvCodeSlice.reducer;
