import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import NewsCard from "../../Components/NewsCard/NewsCard";
import { getNews } from "./service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const LatestNews = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const appInfo = {
    projectDetails: projectInfo,
    accessToken,
  };

  useEffect(() => {
    window.scroll(0,0)
    fetchNews();
  }, []);

  const fetchNews = async () => {
    setLoading(true);
    try {
      const newsResponse = await getNews(appInfo);
      if (newsResponse?.status === 200) {
        setNewsData(newsResponse?.data?.data);
        setLoading(false);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="latestNews">
      <div className="wrapper">
        <div className="head">
          {/* <div className="logoContainer">
                    <img src={require("../../../images/rtimes.png")} alt="Logo" />
                </div> */}
        </div>
        <div className="body position-relative">
          <h1 className="heading">Latest News</h1>
          {
            newsData?.map((item,index)=>(
                <div className="items" key={index}>
                      <NewsCard data={item} />
                </div>
            ))
          }
          {/* <div className="gradientBg">
            <div className="buttonContainer" >
              <button className="showMore">
                Show More
                <DownArrow/>
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
