import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchSubscriptionHistory } from "./service";

const BillingDetails = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [subscriptionHistory, setSubscriptionHistory] = useState({});
  const [expiryDate, setExpiryDate] = useState("");
  const navigate = useNavigate();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {
    window.scroll(0,0)
    getSubscriptionHistory();
  }, []);
  const getSubscriptionHistory = async () => {
    try {
      const response = await fetchSubscriptionHistory(appInfo);
      if (response?.status === 200) {
        setSubscriptionHistory(response?.data);
        const date = new Date(response?.data?.last_purchase?.valid_to);
        const nextday = new Date(response?.data?.last_purchase?.valid_to);
        nextday.setDate(date.getDate() + 1);
        setExpiryDate(nextday.toDateString());
      }
    } catch (err) {}
  };
  return (
    <div className="billingAndSubscription">
      <div className="wrapper">
        <div className="billingDetails">
          <h1 className="heading">Billing Details</h1>
          <h6 className="subHeading">YOUR MEMBERSHIP</h6>
          <div className="billingDetailsContainer">
            <span className="head">Your plan</span>
            <span className="value bold">
              {subscriptionHistory?.last_purchase?.plan}
            </span>

            <span className="head">Your next bill</span>
            <span className="value">{expiryDate}</span>
          </div>
        </div>

        <div className="subscriptionDetails">
          <h1 className="heading">Subscription Details</h1>
          <ul className="subscriptionContainer">
            <li className="head">
              <div className="left">
                <div className="date">Date</div>
                <div className="transactionType">Transaction type</div>
                <div className="servicePeriod">Service period</div>
                <div className="paymentMethod">Payment method</div>
                <div className="subscriptionName">Subscription name</div>
              </div>
              <div className="right">
                <div className="total">Total</div>
              </div>
            </li>
            {subscriptionHistory?.purchase_history?.map((item, index) => (
              <li key={index} className="body">
                <div className="left">
                  <div className="date">{item?.date}</div>
                  <div className="transactionType">
                    {item?.transaction_type}
                  </div>
                  <div className="servicePeriod">{item?.service_period}</div>
                  <div className="paymentMethod">{item?.mode_of_payment}</div>
                  <div className="subscriptionName">
                    {item?.subscription_name}
                  </div>
                </div>
                <div className="right">
                  <div className="total">{item?.total}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="bottom">
          <p>NOTE: We only show up to last 7 billing history</p>
          <div className="buttonContainer" onClick={()=>navigate("/account")}>
            <div className="background" />
            <button >Back</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingDetails;
