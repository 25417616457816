import { createSlice } from "@reduxjs/toolkit";

export const moreInfoSlice=createSlice({
    name:"moreInfo",
    initialState:{
        value:false
    },
    reducers:{
        getMoreInfo:(state,action)=>{
            state.value=action?.payload?.moreInfo
        }
    }
})

export const {getMoreInfo}=moreInfoSlice.actions;
export default moreInfoSlice.reducer