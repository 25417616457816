import React from 'react'
import { useNavigate } from 'react-router-dom'

const GeoBlock = () => {
    const navigate = useNavigate()
  return (
    <div className='notFound' style={{minHeight: '100vh', paddingTop: '40vh'}}>
        <div className="imageContainer">
            {/* <img src={require("../../assets/Images/notFound.png")} alt="" /> */}
        </div>
        <div className="content">
            {/* <h1>404</h1> */}
            <p>Sorry, Access to this content is restricted in your country.</p>
            <div className="buttonContainer" onClick={()=>navigate("/home")} >
                {/* <div className="background"></div> */}
                {/* <button>Back to home</button> */}
            </div>
        </div>
    </div>
  )
}

export default GeoBlock