  import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { useDispatch } from "react-redux";
import { verifyEmail } from "../../Screens/Accounts/Register/service";
const OtpModal = ({
  appInfo,
  userRegisterId,
  setOtpModal,
  tokenAuthenticate,
  updateDeviceAnalytics,
  values,
  urlToRedirect
}) => {
  const [input, setInput] = useState("");
  const [otpErrors, setotpErrors] = useState({
    otp: "",
  });
  const [redirectUrl, setRedirectUrl] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location?.state?.path) {
      setRedirectUrl(urlToRedirect);
    } else {
      setRedirectUrl("/home");
    }
  }, [location?.state]);
  const otpValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!input.trim()) {
      formIsValid = false;
      errors.otp = "Required Otp field";
    }
    setotpErrors(errors);
    return formIsValid;
  };
  const otpHandler = async (e) => {
    e.preventDefault();
    try {
      if (otpValidation) { 
        const response = await verifyEmail(appInfo, input, userRegisterId);
        if (response?.status === 200) {
          localStorage.setItem("userId", userRegisterId);
          if(values?.referralCode){
            localStorage.setItem("referralCode",values?.referralCode)
          }
          dispatch(
            getUser({
            user: userRegisterId
          })
          ) 
          tokenAuthenticate();
          updateDeviceAnalytics(userRegisterId,appInfo,response?.data?.data[0]?.user_email,response?.data?.data[0]?.first_name);
      toast.success(response?.data?.message,{
        position: "top-center",
      }) 
      setTimeout(() => {
          navigate(redirectUrl, { replace: true });
      }, 2500);
 
        } else {
          let errors = {};
          errors.otp = response?.data?.message;
          setotpErrors(errors);
        }
      }
    }  catch(err){
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
    }
  };
  return (
    <div className="otpModal">
       <ToastContainer />
      <div className="overlayOtp"></div>
      <div className="otpContainer">
        <label htmlFor="otp">Enter OTP</label>
        <input
          type="number"
          id="otp"
          value={input || ""}
          onChange={(e) => {
            setInput(e.target.value);
          }}
        />
        {otpErrors.otp && <span className="error">{otpErrors.otp}</span>}
        <div className="buttonContainer" onClick={(e) => otpHandler(e)}>
          <div className="background"></div>
          <button>Verify</button>
        </div>
      </div>
    </div>
  );
};

export default OtpModal;
