import React from "react";

const LandingScreeen = () => {
  return (
    <div className="landingScreen">
      <div className="spotlight rightType">
        <div className="left">
          <h1 className="heading">Welcome to Template</h1>
          <p className="description">
            Watch the latest movies, TV shows, and award-winning
          </p>
          <div className="buttonContainer">
            <div className="background"></div>
            <button>Sign in to Template</button>
          </div>
        </div>
        <div className="right">
          <div className="imageContainer">
            <img
              src="https://media.kvue.com/assets/CCT/images/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c_1140x641.jpg"
              alt="Banner"
            />
          </div>
        </div>
      </div>

      <div className="watchNowSection leftType">
        <div className="left">
          <div className="imageContainer">
            <img
              src="https://media.kvue.com/assets/CCT/images/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c_1140x641.jpg"
              alt="Banner"
            />
          </div>
        </div>

        <div className="right">
                <h1 className="heading">Welcome to Template</h1>
                <p className="description">Watch the latest movies, TV shows, and award-winning</p>
                <div className="buttonContainer">
                    <div className="background"></div>
                    <button>Watch Now</button>
                </div>
            </div>
      </div>

      <div className="other rightType">
        <div className="left">
          <h1 className="heading">Welcome to Template</h1>
          <p className="description">
            Watch the latest movies, TV shows, and award-winning
          </p>
          <div className="buttonContainer">
            <div className="background"></div>
            <button>Sign in to Template</button>
          </div>
        </div>
        <div className="right">
          <div className="imageContainer">
            <img
              src="https://media.kvue.com/assets/CCT/images/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c/e89c1fdc-b92c-49a8-85d1-ac4f67be1e2c_1140x641.jpg"
              alt="Banner"
            />
          </div>
        </div>
      </div>

      <div className="device">
        <div className="left">
            <h1 className="heading">Your favorite channels all in one place</h1>
            <p className="description">With Prime Video Channels, find shows and movies from your favorite channels all in one place. Enjoy with an add-on subscription to Channels of your choice</p>
        </div>
        <div className="right">
            <ul className="devices">
                <li><span>Roku</span></li>
                <li><span>Apple TV</span></li>
                <li><span>Android TV</span></li>
                <li><span>Android</span></li>
                <li><span>IOS</span></li>
                <li><span>Amazon Fire</span></li>
            </ul>
        </div>
      </div>
    </div>
  );
};

export default LandingScreeen;
