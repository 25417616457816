import { fetchApiData,updateApiData } from "../../../utils/utils";

export const getAccountDetails= async (appInfo) => {
    const api="account/details";
    return await fetchApiData(appInfo,api)
}

export const updateAccountdetails = async (appInfo,values) => {
    
    const api="user/update";
    const location = {
        path:"accountUpdate",
        values
    }
    return await updateApiData(appInfo,api,location)
   
}

export const unsubscribe = async (appInfo,subId) => {
  const userId = localStorage.getItem("userId");
  const pubId = appInfo?.projectDetails?.projectConfig?.pubid;
  const countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;

    const data = {
        sub_id:subId,
        userid:userId,
        country_code:countryCode,
        pubid:pubId
    }
    const location = {
        path:"common",
        values:data
    }
    const api ="subscription/cancel"
    return await updateApiData(appInfo,api,location)
}

export const fetchSubscriptionHistory = async (appInfo) => {
    const api = "subscription/history"
    return await fetchApiData(appInfo,api)
}

export const fetchRecentStreamingDevices = async (appInfo) => {
    const api = "account/devices"
    return await fetchApiData(appInfo,api)
}