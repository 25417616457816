import { createSlice } from "@reduxjs/toolkit";

export const selectedChannelSlice=createSlice({
    name:"selectedChannel",
    initialState:{
        value:{}
    },
    reducers:{
        getSelectedChannel:(state,action)=>{
            state.value=action?.payload?.selectedChannel
        }
    }
})

export const {getSelectedChannel}=selectedChannelSlice.actions
export default selectedChannelSlice.reducer