import { createSlice } from "@reduxjs/toolkit";

export const UserDetailsSlice=createSlice({
    name:"userDetails",
    initialState:{
        value:""
    },
    reducers:{
        getUserDetails:(state,action)=>{
            state.value=action?.payload?.userDetails
        }
    }
})

export const {getUserDetails}=UserDetailsSlice.actions
export default UserDetailsSlice.reducer