import { memo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { useSelector } from "react-redux";
import { ReactComponent as RightArrow } from "../../../../assets/Icons/rightArrow.svg";
import { ReactComponent as LeftArrow } from "../../../../assets/Icons/leftArrowIcon.svg";

const modules = [Autoplay, Navigation];

function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const SectionSlider = memo(
  ({
    children,
    title,
    list,
    slidesPerView,
    loop = false,
    spaceBetween = 0,
    className = "",
    link,
    data,
    notViewAll = false,
    episode = false,
    episodeTitle,
    styleElement = true,
    margin = false,
    thumbnail,
  }) => {
    const themeSchemeDirection = '';
    const slider = useRef(null);
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const template = projectInfo?.projectConfig?.config?.TEMPLATE;

    let portraitThumbnail;
    if (thumbnail === "PORTRAIT") {
      portraitThumbnail = true;
    }
    const initSwiper = (swiper) => {
      addCustomClassToVisibleSlides(swiper);
    };

    const addCustomClassToVisibleSlides = (swiper) => {
      if (slider.current) {
        if (swiper) {
          slider.current
            .querySelectorAll(".swiper-slide")
            .forEach((separateSlide) => separateSlide.classList.remove("last"));

          const swiperSlide = slider.current.querySelectorAll(
            ".swiper-slide-visible"
          );

          const lastVisibleSlide = swiperSlide[swiperSlide.length - 1];

          setTimeout(() => {
            if (lastVisibleSlide) {
              lastVisibleSlide.classList.add("swiper-active", "last");
            }
          }, 0);
        }
      }
    };


    return (
      <div className={className}>
        <div className="sectioncontainer">
          <div className="wrapper">
            <div className={"overflow-hidden card-style-slider" + (margin ? " margincontainer" : "")} ref={slider}>
              <div className="d-flex align-items-center justify-content-between px-3 my-4">
                <Link
                  to={`/category/${data?.key}?career=${data?.key}`}
                  state={{ "type": data?.type }}
                  className="text-decoration-none"
                >
                  <div className="heading-container">
                    <h4 className="topHeading">

                      {title}
                      {template != 'TEMPLATE_9' && <span><RightArrow /></span>}
                    </h4>
                    {template == 'TEMPLATE_9' && <span className="show-all">Show All</span>}
                  </div>
                </Link>
              </div>
              <Swiper
                key={themeSchemeDirection}
                dir={themeSchemeDirection}
                className={thumbnail === "PORTRAIT" ? "portrait" : "landscape"}
                slidesPerView={slidesPerView}
                loop={loop}
                watchSlidesProgress
                spaceBetween={spaceBetween}
                navigation={{
                  prevEl: ".swiper-button-prev",
                  nextEl: ".swiper-button-next",
                }}
                breakpoints={{
                  0: styleElement || portraitThumbnail ? {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  } : {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  425: styleElement || portraitThumbnail ? {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  } : {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  576: styleElement || portraitThumbnail ? {
                    slidesPerView: 4,
                    spaceBetween: 0,
                  } : {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  640: styleElement || portraitThumbnail ? {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  } : {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  768: styleElement || portraitThumbnail ? {
                    slidesPerView: 5,
                    spaceBetween: 0,
                  } : {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  980: styleElement || portraitThumbnail ? {
                    slidesPerView: 5,
                    spaceBetween: 0,
                  } : {
                    slidesPerView: 4,
                    spaceBetween: 0,
                  },
                  1025: styleElement || portraitThumbnail ? {
                    slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT : (portraitThumbnail ? 7 : 4),
                    spaceBetween: 0,
                  } : {
                    slidesPerView: 4,
                    spaceBetween: 0,
                  },
                  1500: styleElement || portraitThumbnail ? {
                    slidesPerView: projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT ? projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT : (portraitThumbnail ? 7 : 4),
                    spaceBetween: 0,
                  } : {
                    slidesPerView: 4,
                    spaceBetween: 0,
                  },
                }}
                onSwiper={initSwiper}
                onSlideChange={initSwiper}
                modules={modules}
              >
                {list.map((data, index) => (
                  <SwiperSlide tag="li" key={index + generateUUID() + "slider"}>
                    {children(data)}
                  </SwiperSlide>
                ))}
                <div className=" swiper-button-next">
                  {/* <RightArrow /> */}
                </div>
                <div className=" swiper-button-prev">
                  {/* <LeftArrow /> */}
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

SectionSlider.displayName = "SectionSlider";

export default SectionSlider;
