import { Fragment, memo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const TopTenCard = memo((props) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const thumbnailClass = props.thumbnail === "PORTRAIT" ? "top-ten-portrait" : "top-ten-landscape";
  const showName = props?.state?.videoDetails?.show_name;
  const template = projectInfo?.projectConfig?.config?.TEMPLATE;
  const topTenNumberClass = template === 'TEMPLATE_9' ? "top-ten-numbers top-ten-numbers-extra" : "top-ten-numbers";
  return (
    <Fragment>
      <div className={`iq-top-ten-block ${thumbnailClass}`}>
        <div className={`block-image position-relative ${thumbnailClass}`}>
          <div className="img-box">
            <div className="cardContainer">
              <Link className="overly-images" to={props.link} state={props.state}>
                <img
                  src={props.imagePath}
                  alt="movie-card"
                  className="img-fluid object-cover"
                />
              </Link>
              <span className={`${topTenNumberClass} texture-text`}>{props.countValue}</span>
            </div>
          </div>
          <div className="metaData">
            <h1 className="title" title={showName}>
              {showName}
            </h1>
          </div>
          
        </div>
      </div>
    </Fragment>
  );
});

TopTenCard.displayName = "TopTenCard";
export default TopTenCard;
