import { createSlice } from "@reduxjs/toolkit";

export const UserSubscriptionSlice=createSlice({
    name:"userSubscription",
    initialState:{
        value:{}
    },
    reducers:{
        getUserSubscriptionData:(state,action)=>{
            state.value=action?.payload?.userSubscription
        }
    }
})

export const {getUserSubscriptionData}=UserSubscriptionSlice.actions
export default UserSubscriptionSlice.reducer