import React, { useState } from 'react';
import {ReactComponent as CloseIcon} from "../../assets/Icons/closeFilled.svg"
import { useDispatch, useSelector } from 'react-redux';
import { getScheduleModal } from '../../Redux/ScheduleModal/scheduleModalSlice';
import { convertTimeToLocal, imageUrlCheck, timeComparisonIsAfter, timeFormatter } from '../../utils/utils';

const ScheduleDetailsModal = () => {

    const dispatch = useDispatch()
  const scheduleModalData = useSelector((state)=>state?.scheduleModal?.value?.data);
    const closeHandler = () => {
        dispatch(
            getScheduleModal({
                scheduleModal:{
                    isOpen:false,
                    data:[]
                }
            })
        )
    }
  return (
    <div className='scheduleDetailsModal'>
        <div className="overlayModal"></div>
        <div className="contents">
            <div className="top" style={{backgroundImage:`linear-gradient(rgba(16, 20, 31, 0) 42.42%,rgb(28, 31, 41) 100%),url(${scheduleModalData?.image})`}}>
                <div className="closeIcon" onClick={()=>closeHandler()}>
                    <CloseIcon/>
                </div>
            </div>
            <div className="bottom">
                <div className="channelLogo">
                    <div className="logoContainer">
                        {
                            imageUrlCheck(scheduleModalData?.channel_logo)?
                            (
                                <img src={scheduleModalData?.channel_logo} alt="Logo" />

                            ):
                            (
                                <img src={process.env.REACT_APP_IMAGE_URL+scheduleModalData?.channel_logo} alt="Logo" />

                            )
                        }
                    </div>
                </div>
                <div className="metaData">
                    <h1 className="title">{scheduleModalData?.video_title}</h1>
                    {timeComparisonIsAfter(scheduleModalData?.till) === true?(
                        <span className="startTime">Starts at {timeFormatter(scheduleModalData?.since)}</span>
                        ):(
                            <span className="endTime">Ended at {timeFormatter(scheduleModalData?.till)}</span>

                    )
                }
                    <div className="additionalInfo">
                        {/* <span className="caption">CC</span> */}
                        {
                            scheduleModalData?.rating&&
                        <span className="rating">{scheduleModalData?.rating}</span>
                        }
                    </div>
                    <p className="description">{scheduleModalData?.video_description}</p>
                </div>

            </div>
        </div>
    </div>
  )
}

export default ScheduleDetailsModal