import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as service from "./service";
import { ToastContainer, toast } from "react-toastify";

const SetPassword = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const location = useLocation();
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const accountDetails = location?.state;

  const navigate = useNavigate();

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(() => {

    window.scroll(0, 0);
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    if (validation() === true) {
      updatePassword();
    } else {
      toast.error("Check your fields!", {
        position: "bottom-center",
      });
    }
  };

  const validation = () => {
    let validationStatus = true;
    let errors = {};
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const { password, email, confirmPassword } = values;

    if (!email && !accountDetails?.email_exists) {
      errors.email = "Email is required!";
      validationStatus = false
    } else if (!email?.match(emailRegex) && !accountDetails?.email_exists) {
      errors.email = "Invalid email Id!";
      validationStatus = false
    }

    if (!password) {
      errors.password = "Password is required!";
      validationStatus = false
    } else if (!password?.match(passwordRegex)) {
      errors.password = "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special characters!";
      validationStatus = false
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password is required!";
      validationStatus = false
    } else if (confirmPassword !== password) {
      errors.confirmPassword = "Password not match!";
      validationStatus = false
    }
    setErrors(errors)
    return validationStatus;

  };
  const updatePassword = async () => {

    try {

      console.log('update', values);
      
      const response = await service.updatePassword(appInfo, values);

      if (response?.data?.success === true) {
        toast.success(response?.data?.message, {
          position: "bottom-center",
        });
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        toast.error(response?.data?.message, {
          position: "bottom-center",
        });
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }


  };

  const handleUpdate = (item) => {
    const { name, value } = item?.target;
    handleChange(name, value);
  };

  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <Fragment>
      <div className="ChangePassword">
        <ToastContainer />

        <div className="wrapper">
          <h1 className="heading">Set Password</h1>
          <div className="contents">
            <div className="formSection">
              <form>
                <div className="inputContainer">
                  <label htmlFor="#cpaasword">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={accountDetails?.email_exists ? accountDetails?.user_email : values?.email}
                    disabled={accountDetails?.email_exists}
                    onChange={(item) => handleUpdate(item)}
                  />
                  {errors?.email && (
                    <span className="error">{errors?.email}</span>
                  )}
                </div>
                <div className="inputContainer">
                  <label htmlFor="#newpassword">Enter Password</label>
                  <input
                    type="password"
                    name="password"
                    id="newpassword"
                    value={values?.password}
                    onChange={(item) => handleUpdate(item)}
                  />
                  {errors?.password && (
                    <span className="error">{errors?.password}</span>
                  )}
                </div>
                <div className="inputContainer">
                  <label htmlFor="#confirmpassword">Confirm Password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmpassword"
                    value={values?.confirmPassword || ""}
                    onChange={(item) => handleUpdate(item)}
                  />
                  {errors?.confirmPassword && (
                    <span className="error">{errors?.confirmPassword}</span>
                  )}
                </div>

                <div
                  className="buttonContainer"
                  onClick={(e) => submitHandler(e)}
                >
                  <div className="background"></div>
                  <button type="submit" className="bg-button">
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SetPassword;
