import { createSlice } from "@reduxjs/toolkit";

export const ProjectInfoSlice=createSlice({
    name:"projectInfo",
    initialState:{
        value:{}
    },
    reducers:{
        getProjectInfo:(state,action)=>{
            state.value=action?.payload?.projectInfo
        }
    }
})

export const {getProjectInfo}=ProjectInfoSlice.actions;
export default ProjectInfoSlice.reducer