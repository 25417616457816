import React from "react";

const Loading = () => {
  return (
    <div className="loadingContainer">
      <div className="loading">
        <div className="loadingOverlay"></div>
        <span class="loader"></span>
        {/* <div className="loadingText">
            <h1>Loading</h1>
            <span>•</span>
            <span>•</span>
            <span>•</span>

        </div> */}
      </div>
    </div>
  );
};

export default Loading;
