import { fetchApiData, fetchApiDataV2, updateApiDataV2, fetchPublicApi ,commentImageUpload,commentGifUpload} from "../../utils/utils";

export const getVideoDetails = async (id,appInfo) =>{
    const api =`video/${id}`
    return await fetchApiDataV2(appInfo,api)
}

export const getShowDetails=async (appInfo,showId)=>{
    const api=`show/${showId}`
    return await fetchApiDataV2(appInfo,api)
}
export const getSimilarVideos=async (appInfo,showId)=>{
    const api=`show/similar/${showId}`
    return await fetchApiDataV2(appInfo,api)
}
export const commentImageUploadThumbnail = async (data,appInfo) =>{
    const api =`comments/image`
  
    return await commentImageUpload(data,appInfo,api)
  }
  export const commentGifUploadThumbnail = async (data,appInfo) =>{
    const api =`comments/gif`
  
    return await commentGifUpload(data,appInfo,api)
  }
