import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import VideoJS from '../../Components/VideoJsPlayer/VideoJS';
import { getTrailer } from './service'
import { useSelector } from 'react-redux';
import { getPlayerToken } from "../../network/service";
import Loading from '../../Components/Loading/Loading';

function Trailer() {
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const sessionId = useSelector((state) => state?.sessionId?.value);
    const accessToken = useSelector((state) => state?.accessToken?.value);
    const [teaser, setTeaser] = useState("")
    const [title, setTitle] = useState("")
    const [videoTitle,setVideoTitle] = useState("")
    const [videoPlayer, setVideoPlayer] = useState()
    const [trailerUrl, setTrailerUrl] = useState("")
    const [description,setDescription] = useState("")
    const [loading, setLoading] = useState(true)
    const [error,setError] = useState(false)
    const location = useLocation();
    const navigate = useNavigate()
    const watchNowButtonHandler = () => {
        navigate(`/show-details/${videoTitle}`)
    }


    const appInfo = {
        projectDetails: projectInfo,
        accessToken: accessToken,
        sessionId,
    };

    const fetchTrailer = async (vanityUrl) => {
        const response = await getTrailer(appInfo, vanityUrl)
        setTeaser(response.data.data.teaser)
        setTitle(response.data.data.title)
        setVideoTitle(response?.data?.data?.vanity_url)
        setDescription(response?.data?.data?.synopsis)
    }
    useEffect(() => {

        if (location?.pathname) {
            let vanityUrl = location?.pathname.split("/")[2];
            if (vanityUrl !== "null" && vanityUrl !== "undefined" && vanityUrl) {
                fetchTrailer(vanityUrl);
            }
        }

    }, [location?.pathname])

    useEffect(() => {
        if(teaser)
        fetchPlayerToken()
    }, [teaser])

    useEffect(() => {
        setLoading(true);
        setVideoPlayer(null);
        setError(false);
        
        let delayTimer;
        let delayPassed = false;
        if (!trailerUrl) {
            setError(true);
            setLoading(false);
            return;
        }

        if (trailerUrl) {
            const videoJsOptions = {
                autoplay: true,
                controls: true,
                responsive: true,
                fluid: true,
                controlBar: {
                    pictureInPictureToggle: false,
                },
                sources: [
                    {
                        src: `${trailerUrl}`,
                        type: "application/x-mpegURL",
                    },
                ],
            };

            setVideoPlayer(
                <VideoJS 
                    options={videoJsOptions} 
                    onReady={handlePlayerReady} 
                />
            );
            setLoading(false);
        }
        else {
            
            delayTimer = setTimeout(() => {
                delayPassed = true;
                setError(true);
                setLoading(false);
            }, 4000); 
        }
        
        return () => {
            clearTimeout(delayTimer); 
        };
    }, [trailerUrl]);

    const handlePlayerReady = (player) => {
        player.play().then(() => {
            console.log("Video is autoplaying");
        }).catch((error) => {
            console.log("Autoplay failed", error);
        });

        player.on("autoplay-fail", () => {
            console.log("Autoplay failed, attempting to play manually");
            player.play();
        });
    };

    const fetchPlayerToken = async () => {
        if (teaser) {
            const arr = teaser.split("/").reverse();
            if (arr.length >= 2) {
                try {
                    const playerTokenResponse = await getPlayerToken(appInfo, arr[1]);
                    if (playerTokenResponse?.status === 200) {
                        setTrailerUrl(
                            "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
                            arr[1] +
                            "&token=" +
                            playerTokenResponse?.data?.data +
                            "&type=trailer&pubid=" + appInfo?.projectDetails?.projectConfig?.pubid
                        );
                        return playerTokenResponse?.data?.data;
                    }
                } catch (err) {
                    setError(true);
                }
            }
        }
    }
    if (loading) {
        return <Loading />;
    }
    return (
        <div className='trailerPage'>
            <div className="wrapper containerHeight">
                <div className='trailer'>
                    <div className="trailerVideoPlayer">
                        {videoPlayer}
                    </div>
                    <div className="trailerPagecontent">
                        <div className="videoTitle">
                            <h1 className='title'>{title}</h1>
                        </div>
                        <div className="watchButton">
                            <button onClick={watchNowButtonHandler}>Watch Now</button>
                        </div>
                    </div>
                    <div className="trailerDescription">
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trailer
