import { createSlice } from "@reduxjs/toolkit";

export const DeviceIdSlice=createSlice({
    name:"deviceId",
    initialState:{
        value:""
    },
    reducers:{
        getDeviceId:(state,action)=>{
            state.value=action?.payload?.deviceId
        }
    }
})

export const {getDeviceId}=DeviceIdSlice.actions;
export default DeviceIdSlice.reducer