import { createSlice } from "@reduxjs/toolkit";

export const liveScheduleDataSlice=createSlice({
    name:"liveSchedule",
    initialState:{
        value:{}
    },
    reducers:{
        getLiveScheduleData:(state,action)=>{
            state.value=action?.payload?.liveSchedule
        }
    }
})

export const {getLiveScheduleData}=liveScheduleDataSlice.actions
export default liveScheduleDataSlice.reducer