import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const TopBanner = () => {
    const [searchInputValue,setSearchInputValue] = useState("")
    const navigate = useNavigate()
    const socialMediaLinks = {
        facebook:"https://www.facebook.com/groups/livingintheprivate",
        twitter:"https://twitter.com/hisadvocatestv",
        linkedIn:"https://www.linkedin.com/in/kelbytsmith/",
        instagram:"https://www.instagram.com/hisadvocates/",
        youtube:"https://www.youtube.com/c/HISAdvocatesssm",
        telegram:"https://t.me/hisadvocates",
        android:"https://play.google.com/store/apps/details?id=com.tvstartup.hisadvocates",
        ios:"https://apps.apple.com/us/app/hisadvocates/id1645053049",
        skype:"https://join.skype.com/ZPUcFQOwJLGy",
        whatsapp:"https://chat.whatsapp.com/CL7zyyTbFDA4Lubz0VPZqX",
        bitchute:"https://www.bitchute.com/channel/hisadvocates/",
        signal:"https://signal.group/#CjQKILoUuQv7npm3hkIJqZ8NKAY96fJQeEBKUI2pREi163oxEhBg8r2aYHBmGVgu61vOPr_B"
    }

    const searchHandler = () => {
        if(searchInputValue){
            navigate(`/search?query=${searchInputValue}`)
            setSearchInputValue("")
        }
    }
  return (
    <div className='topBanner'>
        <div className="head">
            <span className="contact">
                <span className="icon">
                <i className="fa-solid fa-phone"></i>
                </span>
                <a href="tel:8444472386">(844) 447-2386 (HIS-Advo) Ext. 2</a>
            </span>
            <div className="social">
                <span className="label">Follow Us:</span>
                <div className="icons">
                    <span className="icon" style={{color:"#3b5998"}} onClick={()=>window.open(socialMediaLinks?.facebook,"_blank")}>
                        <i class="fa-brands fa-facebook-f"></i>
                    </span>
                    <span className="icon" style={{color:"#1da1f2"}} onClick={()=>window.open(socialMediaLinks?.twitter,"_blank")}>
                    <i class="fa-brands fa-twitter"></i>
                    </span>
                    <span className="icon" style={{color:"#0e76a8"}} onClick={()=>window.open(socialMediaLinks?.linkedIn,"_blank")}>
                    <i class="fa-brands fa-linkedin-in"></i>
                    </span>
                    <span className="icon" style={{color:"#fa9137"}} onClick={()=>window.open(socialMediaLinks?.instagram,"_blank")}>
                    <i class="fa-brands fa-instagram"></i>
                    </span>
                    <span className="icon" style={{color:"#cd201f"}} onClick={()=>window.open(socialMediaLinks?.youtube,"_blank")}>
                    <i class="fa-brands fa-youtube"></i>
                    </span>
                    <span className="icon" style={{color:"#08c"}} onClick={()=>window.open(socialMediaLinks?.telegram,"_blank")}  >
                   <i class="fa-brands fa-telegram"></i>
                    </span>
                    <span className="icon" style={{color:"#3DDC84"}} onClick={()=>window.open(socialMediaLinks?.android,"_blank")}>
                    <i class="fa-brands fa-android"></i>
                    </span>
                    <span className="icon" style={{color:"#3b5998"}} onClick={()=>window.open(socialMediaLinks?.ios,"_blank")}>
                    <i class="fa-brands fa-apple"></i>
                    </span>
                    <span className="icon" style={{color:"#175ce6"}} onClick={()=>window.open(socialMediaLinks?.skype,"_blank")}>
                    <i class="fa-brands fa-skype"></i>
                    </span>
                    <span className="icon" style={{color:"#267f1b"}} onClick={()=>window.open(socialMediaLinks?.whatsapp,"_blank")}>
                    <i class="fa-brands fa-whatsapp"></i>
                    </span>
                    <span className="icon" style={{color:"#f80404"}} onClick={()=>window.open(socialMediaLinks?.bitchute,"_blank")}>
                    <i class="fa-regular fa-moon"></i>
                    </span>
                    <span className="icon" style={{color:"#3e76f0"}} onClick={()=>window.open(socialMediaLinks?.signal,"_blank")}>
                    <i class="fa-regular fa-comment"></i>
                    </span>
                </div>
            </div>
        </div>
        <div className="body">
           
                {/* <div className="imageContainer" onClick={()=>window.location.reload()}> */}
                <div className="imageContainer">
                    <a href={"https://store.hisadvocates.org/state-Citizen-Passport"} target="_blank">
                        <img src="https://gizmeon.mdc.akamaized.net/PUB-50046/banner/home.jpg" alt="Banner" />
                    </a>
                </div>
           
            <div className="searchContainer">
                <input type="text" value={searchInputValue || ""} onChange={(e)=>setSearchInputValue(e.target.value)} placeholder='Search Show here...' onKeyDown={(e) =>
                    e.key === "Enter" ? searchHandler() : ""
                  }/>
            </div>
        </div>
    </div>
  )
}

export default TopBanner