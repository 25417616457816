import React, { useEffect, useState } from "react";
import { fetchAppInfo } from "../../network/service";
import parse from "html-react-parser";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../Components/Loading/Loading";

const ContactUs = () => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scroll(0,0)
    fetchContactUs();
  }, []);

  const fetchContactUs = async () => {
    setLoading(true);
    try {
      const params = {
        type: "CONTACT",
      };
      const response = await fetchAppInfo(params);
      if (response?.status === 200) {
        setData(response?.data?.data?.value);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  if(loading){
    return(
      <Loading/>
    )
  }
  return (
    <div className="contactUs">
      <ToastContainer/>
      <div className="wrapper">{parse(data?data:"<h1 style='text-align:center;color:red'>Update Soon</h1>")}</div>
      
    </div>
  );
};

export default ContactUs;
