import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { checkOperatingSystem } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';

const OpenApp = ({setIsOpenAppFeature,setIsOpenFeatureClosed}) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const [ done,setDone] = useState()
  const navigate = useNavigate()
  const notNowHandler = () => {
    setIsOpenAppFeature(false)
    setIsOpenFeatureClosed(true)
  }
  // window.addEventListener('visibilitychange', function() {
  //   clearTimeout(openAppTimeout);
  // });
  // useEffect(()=>{
  //   if(done){
  //     setTimeout(() => {
  //       window.location.replace(projectInfo?.projectConfig?.config?.APPLINK_URL)
  
  
  //     }, 1000);

  //   }

  // },[done])

  const openAppHandler = () => {
    window.location.href = projectInfo?.projectConfig?.config?.APPLINK_URL
  }
  return (
    <div className='openAppContainer'>
        <h1>{projectInfo?.projectConfig?.config?.TAB_TITLE} is better on the app</h1>
        <p>Open in the {projectInfo?.projectConfig?.config?.TAB_TITLE} app to get the full experience.</p>
        <div className="buttons">
            <button onClick={notNowHandler} className="notNow">Not now</button>
            <button onClick={openAppHandler} className="openApp">Switch to the app</button>
        </div>
    </div>
  )
}

export default OpenApp