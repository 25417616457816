import React from 'react'
import Skeleton,{SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const LoadingBanner = ({minHeight}) => {
  return (
    <div style={{minHeight:minHeight,width:"100%"}}>
         <SkeletonTheme baseColor="#202020" highlightColor="#444" height={minHeight} >
    <p>
      <Skeleton count={1} />
    </p>
  </SkeletonTheme>
    </div>
  )
}

export default LoadingBanner