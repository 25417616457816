import { createSlice } from "@reduxjs/toolkit";

export const MyListSlice=createSlice({
    name:"myList",
    initialState:{
        value:[]
    },
    reducers:{
        getMyListData:(state,action)=>{
            state.value=action?.payload?.myList
        }
    }
})

export const {getMyListData}=MyListSlice.actions;
export default MyListSlice.reducer