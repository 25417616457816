import {
  TimelineWrapper,
  TimelineBox,
  TimelineTime,
  TimelineDivider,
  TimelineDividers,
  useTimeline
} from "planby";
import { convertTimeToLocal, convertTimeToUTC, convertTo12HourFormat, getCurrentTime } from "../../../utils/utils";
import { useEffect, useState } from "react";
import moment from "moment";

export function Timeline({
  isBaseTimeFormat,
  isSidebar,
  dayWidth,
  hourWidth,
  numberOfHoursInDay,
  offsetStartHoursRange,
  sidebarWidth
}) {
  const { time, dividers, formatTime } = useTimeline(
    numberOfHoursInDay,
    isBaseTimeFormat
  );
  const [seconds,setSeconds] = useState()
  const [currentTime, setCurrentTime] = useState(moment().format('h:mm:ss A'));

  useEffect(() => {
    const interval = setInterval(() => {
      const newTime = moment().format('h:mm:ss A');
      if (newTime !== currentTime) {
        setCurrentTime(newTime);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [currentTime]);
  const dayFlag = (time) => {
    if(time>12){
      return 'Tommorrow'
    }else {
      return ' Today'
    }
  }

  const currentTimeline = (timelineTime) => {
    let currentTime = getCurrentTime()
    if(timelineTime===currentTime.split(":")[0].replace("0","")){
     return currentTime.split(":")[1]
    }
  }

  const currentTimePositionController = () => {
    let currentTime = getCurrentTime().split(":")[1].split(" ")[0]
    let posititon = currentTime/60 * 100 -25 + "%"
    return posititon;
  }

  const timeFormat = (index,offsetStartHoursRange) => {
    if(formatTime(index + offsetStartHoursRange).split(":")[0]>12){
      return convertTo12HourFormat(formatTime(index + offsetStartHoursRange))
    }else{
      return formatTime(index + offsetStartHoursRange).toLowerCase()
    }
  }
  
  const renderTime = (index) => (
    <TimelineBox key={index} width={hourWidth}>
      <TimelineTime>
        {/* {convertTimeToUTC(formatTime(index + offsetStartHoursRange)).toString().toLowerCase()} */}
        {/* {formatTime(index + offsetStartHoursRange).toLowerCase()} */}
        {timeFormat(index,offsetStartHoursRange)}
      </TimelineTime>
      {/* <div className="timelineDate">
        {dayFlag(formatTime(index + offsetStartHoursRange).split(":")[0])}
      </div> */}
      <TimelineDividers>
        {renderDividers()}
        {/* {currentTimeline(formatTime(index + offsetStartHoursRange).split(":")[0])?<span style={{left:currentTimePositionController()}} className="currentTime">{getCurrentTime()}</span>:null} */}
      </TimelineDividers>
    </TimelineBox>
  );

  const renderDividers = () =>
    dividers.map((_, index) => (
      <TimelineDivider key={index} width={hourWidth} />
    ));

    

  return (
    <TimelineWrapper
      dayWidth={dayWidth}
      sidebarWidth={sidebarWidth}
      isSidebar={isSidebar}
    >
      {time.map((_, index) => renderTime(index))}
    </TimelineWrapper>
  );
}
