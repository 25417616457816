import React, { useEffect, useRef, useState } from "react";
import { checkOperatingSystem } from "../../utils/utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const VerifyApp = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const navigate = useNavigate();
  const [device, setDevice] = useState();
  const openAppRedirectUrl = localStorage.getItem("openAppRedirectUrl");
  const openAppRedirectUrlWithSearhQuery = localStorage.getItem("openAppRedirectUrlWithSearhQuery");
  // if (openAppRedirectUrlWithSearhQuery) {
  //   navigate(openAppRedirectUrl + openAppRedirectUrlWithSearhQuery);
  // } else {
  //   navigate(openAppRedirectUrl);
  // }
  const buttonRef = useRef(null);
  // setTimeout(() => {
  //   const targetElement = document.getElementById('get');
  //   targetElement.click();
    
  // }, 600);

  window.location.reload()
  // window.open(projectInfo?.projectConfig?.config?.APPLINK_URL)
  useEffect(() => {
    
    if(projectInfo?.projectConfig?.config?.APPLINK_URL){
      const targetElement = document.getElementById('get');
    targetElement.click();

    }
    

   
  }, [projectInfo]);

  const simulateClick = () => {
      window.location.replace(projectInfo?.projectConfig?.config?.APPLINK_URL)
  };
  const check = () => {
    const targetElement = document.getElementById('get');
    targetElement.click();
  }
  return <div style={{padding:"100px"}}>
    <button id="get" ref={buttonRef} onClick={simulateClick}>GEt app</button>
    <br/>
    <button ref={buttonRef} onClick={check}>check</button>
  </div>;
};

export default VerifyApp;
