import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { AES } from 'crypto-js';
import CryptoJS from 'crypto-js';


const initializeFirebase = (projectInfo) => {

  const CREDENTIALS_ENCRYPTION_KEY = 'B^(C4p*9Wj#Nf4dHsg9T';

  const configKey = projectInfo?.projectConfig?.config?.WEBSITE_FIREBASE_JSON_FILE;

  try {
    const decryptedValue = AES.decrypt(configKey, CREDENTIALS_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    const decryptedConfig = JSON.parse(decryptedValue);

    const firebaseConfig = {
      apiKey: decryptedConfig.apiKey,
      authDomain: decryptedConfig.authDomain,
      databaseURL: decryptedConfig.databaseURL,
      projectId: decryptedConfig.projectId,
      storageBucket: decryptedConfig.storageBucket,
      messagingSenderId: decryptedConfig.messagingSenderId,
      appId: decryptedConfig.appId,
      measurementId: decryptedConfig.measurementId
      
    };
    const app = initializeApp(firebaseConfig);
    return getDatabase(app);
  } catch (error) {
    console.error("Decryption Error:", error);
    return null;
  }

};

export default initializeFirebase;
