import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

const ProtectedRoutes = () => {
    const user = useSelector((state) => state?.user?.value);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!user) {
            const searchParams = new URLSearchParams(location.search);
            const queryParams = searchParams.toString();
            const pathWithParams = location.pathname + (queryParams ? `?${queryParams}` : '');
            navigate('/login', { state: { path: pathWithParams } });
        }
    }, [user, navigate, location]);

    if (user) {
        return <Outlet />;
    } else {
        return null; // Or any placeholder while redirecting
    }
}

export default ProtectedRoutes;
