import React, { memo, Fragment, useState, useEffect } from "react";
import SectionSlider from "./Components/Slider/SectionSlider";
import TopTenCard from "./Components/Cards/TopTenCard";
import { useSelector } from "react-redux";

const  TopTrendingEpisodes = memo(({ data }) => {
  const [title, setTitle] = useState("");
  const [shows, setShows] = useState([]);
  let count = 0;
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  useEffect(() => {
    setShows(data?.shows)
    setTitle(data?.category_name)
   
  }, [data]);
  const thumbnail = data?.thumbnail_orientation ? data?.thumbnail_orientation : projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION;
  return (
    <Fragment>  
    {thumbnail=== "PORTRAIT" ? <SectionSlider title={title} list={shows} className="top-ten-block" data={data} thumbnail={thumbnail} slidesPerView={5} >
      {(shows) => {
        const link = shows?.video_id ?(`/videos/${shows?.vanity_url}`):(`/show-details/${shows?.vanity_url}`);   
        count += 1;
        return (
          <TopTenCard
            imagePath={shows.logo}
            countValue={count}
            link={link}
            state={{ videoDetails:shows }}
            thumbnail={thumbnail}
          />
        )
      }}
    </SectionSlider>
    :
    <SectionSlider title={title} list={shows} className="top-ten-block" data={data} slidesPerView={5} styleElement={false} thumbnail={thumbnail}>
      {(shows) => {
        const link = shows?.video_id ?(`/videos/${shows?.vanity_url}`):(`/show-details/${shows?.vanity_url}`);   
        count += 1;
        return (
          <TopTenCard
            imagePath={shows.logo_thumb}
            countValue={count}
            link={link}
            state={{ videoDetails:shows }}
            
          />
        )
      }}
    </SectionSlider>
    }
  </Fragment>
  );
});

TopTrendingEpisodes.displayName = " TopTrendingEpisodes";
export default  TopTrendingEpisodes;

