import { createSlice } from "@reduxjs/toolkit";

export const sessionIdSlice=createSlice({
    name:"sessionId",
    initialState:{
        value:""
    },
    reducers:{
        getSessionIdValue:(state,action)=>{
            state.value=action?.payload?.sessionId
        }
    }
})

export const {getSessionIdValue}=sessionIdSlice.actions;
export default sessionIdSlice.reducer