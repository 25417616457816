import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-ads';
import 'videojs-ima';
import { ssaiAdParam } from '../../utils/utils';
export const VideoJS = (props) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  // const pauseRef = React.useRef(null);
  const { options, onReady, pauseVideo, isTrailer, poster, is_live } = props;
  const location = useLocation()
  let uid = localStorage.getItem("gid");
  let user_id = localStorage.getItem("userId");
  if (user_id) {
    uid = user_id
  }
  const appInfo = {
    projectDetails: projectInfo
  }

  videojs.Vhs.xhr.beforeRequest = function (options) {
    if (options?.uri.includes(".m3u8?id=")) {
      options.headers = {
        pubid: projectInfo?.projectConfig?.pubid,
        channelid: projectInfo?.projectConfig?.channelid,
        uid: uid,
        "access-token": accessToken
      };
    }
    return options;
  };


  React.useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;
      if (props?.liveData?.channel_id) {
        if (props?.liveData?.ssai_enabled) {
          ssaiAdParam(props?.liveData, appInfo).then((response) => {
            createSSAI(props?.liveData.ssai_link, videoElement, response, false)

          })
        } else {
          const player = playerRef.current = videojs(videoElement, { ...options, autoplay: isTrailer || is_live ? true : false }, () => {
            onReady && onReady(player);
          });
        }
      } else {
        const player = playerRef.current = videojs(videoElement, { ...options, autoplay: isTrailer || is_live ? true : false }, () => {
          onReady && onReady(player);
        });
      }


      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      //   const player = playerRef.current;

      //   player.autoplay(options.autoplay);
      //   player.src(options.sources);
    }
  }, [options, videoRef]);

  useEffect(() => {
    let clear = true
    if (props?.liveData) {
      createSSAI("", "", "", clear)

    }
    return () => {
      createSSAI("", "", "", clear)
    }
  }, [props?.liveData])

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    if (props?.adUrl) {
      var imaOptions = {
        adTagUrl: props?.adUrl
      };

      player.ima(imaOptions)

      // Pause the video when ad starts
      player.on('ads-ad-started', () => {
        const videoElement = videoRef.current;
        // console.log('ads-ad-started', videoElement);

        try {
          // console.log('Before pause:', player.paused());  // Log current state
          document.getElementById('vjs_video_player_html5_api').pause()
          // console.log('After pause:', player.paused());  // Log state after pause
        } catch (error) {
          console.log('error', error);

        }




        // videoElement.pause();
      });

      // Resume the video when ad ends
      player.on('ads-ad-ended', () => {
        // console.log('ads-ad-ended');

        player.play();
      });

      player.on('ima3-started', () => {
        // console.log('ima3 Ad started');
        player.pause(); // Pause video when ad starts
      });

      player.on('ima3-complete', () => {
        // console.log('ima3 Ad completed');
        player.play(); // Resume video when ad ends
      });

    }

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;

      }
    };


  }, [videoRef]);

  function createSSAI(url, videoElem, adsParams, clearFlag) {


    let eachSecondInterval;
    let refreshInterval;
    if (clearFlag === false) {

      sessionCreation(url);

      var arr = url.split("/");
      var result = arr[0] + "//" + arr[2]
      var domainOfUrl = result;



      var previousTime = 0;
      var beaconTime = [];
      var trackingUrl = domainOfUrl;
      var beaconData = []
      var whereYouAt = 0;
      eachSecondInterval = setInterval(eachSecond, 1000);




      function isInArray(value, array) {
        return array.indexOf(value);
      }


      function eachSecond() {


        whereYouAt = whereYouAt + 1;
        whereYouAt = Math.round(whereYouAt)



        if (whereYouAt != previousTime) {
          previousTime = whereYouAt;

          if (beaconTime.length > 0) {

            if (isInArray(whereYouAt, beaconTime) > -1) {

              var temp = beaconData[isInArray(whereYouAt, beaconTime)].beaconUrls;
              for (var i = 0; i < temp.length; i++) {

                callAdTrackingUrl(temp[i])
              }



            }



          }

        }
      }


      function callAPI(url) {

        var request = new XMLHttpRequest();
        request.onreadystatechange = function () {
          if (request.readyState == 4 && request.status == 200) {


            var trackingData = JSON.parse(request.responseText);

            var avails = trackingData.avails;



            for (let k = 0; k < avails.length; k++) {


              var avail = avails[k];

              var ads = avail.ads;


              for (let j = 0; j < ads.length; j++) {

                var trackingEvents = ads[j].trackingEvents;

                for (k = 0; k < trackingEvents.length; k++) {

                  var trackingEvent = trackingEvents[k]



                  if (trackingEvent && trackingEvent.eventType) {
                    var eventType = trackingEvent.eventType;
                    if (eventType == "start" || eventType == "firstQuartile" || eventType == "firstQuartile" || eventType == "midpoint" || eventType == "impression") {
                      beaconTime.push(Math.round(trackingEvent.startTimeInSeconds))
                      beaconData.push(trackingEvent)


                    }
                  }

                }



              }




            }


          } else if (request.status == 502) {

            beaconTime = [];

          }

        }
        request.open("GET", url);
        request.send(null);
      }

      function sessionCreation(theUrl) {

        var data = JSON.stringify(adsParams);

        var xhr = new XMLHttpRequest();


        xhr.addEventListener("readystatechange", function () {
          if (this.readyState === 4) {
            var jsonResult = JSON.parse(this.responseText)


            // const player = videojs(videoElem);

            const player = playerRef.current = videojs(videoElem, options, () => {
              onReady && onReady(player);
            });

            if (jsonResult) {
              player.src({
                src: domainOfUrl + jsonResult.manifestUrl,
                type: 'application/x-mpegURL',
              });
              trackingUrl = trackingUrl + jsonResult.trackingUrl;
              refreshInterval = setInterval(refresh, 10000);
            }




          }
        });

        xhr.open("POST", theUrl);
        xhr.setRequestHeader("content-type", "application/json");


        xhr.send(data);


      }

      function refresh() {
        callAPI(trackingUrl);
      }



      function callAdTrackingUrl(theUrl) {


        var i = document.createElement("img");
        i.src = theUrl;



      }
    } else if (clearFlag === true) {
      clearTimeout(eachSecondInterval)
      clearTimeout(refreshInterval)
    }




  }
  return (
    <div data-vjs-player>

      <video ref={videoRef} id='vjs_video_player' className='video-js vjs-big-play-centered' poster={poster} >
        {
          props?.closedCaptions?.map((item, index) => (
            <track key={index} kind='captions' src={item?.closed_caption_url} srclang={item?.short_code} label={item?.language_name} default={projectInfo?.projectConfig?.config?.DEFAULT_CAPTIONS_ON == "true" ? true : false} />
          ))
        }
        {
          props?.subtitles?.map((item, index) => (
            <track key={index} kind='captions' src={item?.subtitle_url} srclang={item?.short_code} label={item?.language_name} default={projectInfo?.projectConfig?.config?.DEFAULT_CAPTIONS_ON == "true" ? true : false} />
          ))
        }
      </video>
    </div>
  );
}

export default VideoJS;