import React, { useEffect, useState } from 'react'
import { fetchAppInfo } from '../../network/service';
import parse from "html-react-parser";
import { ToastContainer, toast } from "react-toastify";
import Loading from '../../Components/Loading/Loading';

const TermsAndConditions = () => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scroll(0,0)
    fetchTermsAndConditions();
  }, []);

  const fetchTermsAndConditions = async () => {
    setLoading(true);
    try{
      const params = {
        type: "TERMS_AND_CONDITIONS",
      };
      const response = await fetchAppInfo(params);
      if (response?.status === 200) {
        setData(response?.data?.data?.value);
        setLoading(false)
      }
    }catch(err){
      setLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
    
  };
  if(loading){
    return(
      <Loading/>
    )
  }
  return (
    <div className="termsAndConditions">
      <ToastContainer/>

      <div className="wrapper">{parse(data)}</div>
      {/* <div dangerouslySetInnerHTML={{__html: '<strong>strong text</strong>'}} /> */}
    </div>
  )
}

export default TermsAndConditions
