import { createSlice } from "@reduxjs/toolkit";

export const selectedScheduleSlice=createSlice({
    name:"selectedSchedule",
    initialState:{
        value:null
    },
    reducers:{
        getSelectedSchedule:(state,action)=>{
            state.value=action?.payload?.selectedSchedule
        }
    }
})

export const {getSelectedSchedule}=selectedScheduleSlice.actions
export default selectedScheduleSlice.reducer