import { createSlice } from "@reduxjs/toolkit";

export const AppInfoSlice=createSlice({
    name:"appInfo",
    initialState:{
        value:{}
    },
    reducers:{
        getAppInfo:(state,action)=>{
            state.value["country_name"]=action?.payload?.appInfo?.country
            state.value["city"]=action?.payload?.appInfo?.city
            state.value["latitude"]=action?.payload?.appInfo?.lat
            state.value["longitude"]=action?.payload?.appInfo?.lon
            state.value["IPv4"]=action?.payload?.appInfo?.query
            state.value["state"]=action?.payload?.appInfo?.region
            state.value["country_code"]=action?.payload?.appInfo?.countryCode
        }
    }
})

export const {getAppInfo}=AppInfoSlice.actions;
export default AppInfoSlice.reducer