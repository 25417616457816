import { fetchShorts,commentGifUpload,commentImageUpload,likeHandlerShorts,fetchSpecificShorts } from "../../utils/utils";
export const getShorts = async (appInfo, params) => {
  const api = "shorts/list";

  return await fetchShorts(appInfo, api,params);
};
export const getSingleShorts = async (appInfo,video_id, params) => {
  const api = "shorts/";

  return await fetchSpecificShorts(appInfo, video_id,params);
};
export const commentImageUploadThumbnail = async (data,appInfo) =>{
  const api =`comments/image`

  return await commentImageUpload(data,appInfo,api)
}
export const commentGifUploadThumbnail = async (data,appInfo) =>{
  const api =`comments/gif`

  return await commentGifUpload(data,appInfo,api)
}
export const likeHandler = async (id,liked,appInfo) =>{
  
  const api =`like/video`
const data = {
  id,
  liked
}
  return await likeHandlerShorts(api,data,appInfo)
}