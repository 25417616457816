import { createSlice } from "@reduxjs/toolkit";

export const podcastModalSlice=createSlice({
    name:"podcastModal",
    initialState:{
        value:{
            isOpen:false,
        }
    },
    reducers:{
        getPodcastModal:(state,action)=>{
            state.value=action?.payload?.podcastModal
        }
    }
})

export const {getPodcastModal}=podcastModalSlice.actions
export default podcastModalSlice.reducer