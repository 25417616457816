import React, { useState, useEffect, useRef } from "react";
import ShowCard from "../../Components/ShowCard/ShowCard";
import * as service from "./service";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../Components/Loading/Loading";
import { rowItemCount } from "../../utils/utils";
import { useWindowWidth } from "@react-hook/window-size";
import useScrollPosition from "../../Components/ScrollPosition/useScrollPosition";
import ShortsCard from "../Shorts/ShortsCard"
const Search = () => {
  const accessToken = useSelector((state) => state.accessToken?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  const [searchData, setSearchData] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [loading, setLoading] = useState(true);
  const [rowItemsClass,setRowItemsClass] = useState("")
  const [sliceCount,setSliceCount] = useState(20)
  const [searchDataCategories, setSearchDataCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState();
  const [thumbnailOrientation, setThumbnailOrientation] = useState();
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const windowSize = useWindowWidth()
  const containerRef = useRef(null)
  const scrollPosition = useScrollPosition();


  let query = params?.get("query");

  const appInfo = {
    accessToken: accessToken,
    projectDetails: projectInfo,
  };

  useEffect(()=>{
    if(!loading && sliceCount<searchData?.length){
      lazyLoad()
    }
  },[loading,scrollPosition,searchData])

  useEffect(() => {
    if(windowSize>980){
      if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT){
        setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT))
      }else{
        setRowItemsClass("rowCount4")
      }
    }else{
      setRowItemsClass("")
    }
  }, [projectInfo,windowSize]);


  useEffect(() => {
    window.scroll(0,0)
    setLoading(true);
    if(query === null){
      setSearchData(null);
      setSearchInput(null)
      setLoading(false);
      }else{
              setSearchInput(query);
          fetchShowDetails();
      }
  }, [query]);

  const fetchShowDetails = async()=>{
    try{
      const response = await service?.getShows(appInfo, query,location?.state?.type);
      if (response?.status === 200) {
          if(response?.data?.data[0]?.category_id){
            setSearchData(response?.data?.data[0]?.shows);
            setSearchDataCategories(response?.data?.data);
            setThumbnailOrientation(response?.data?.data[0]?.thumbnail_orientation || projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION)
          }
          else{
            setSearchData(response?.data?.data); 
          }
      setActiveCategory(response?.data?.data[0]?.category_name)
      setLoading(false);
    } else {
      setLoading(false);
    }
    }catch(err){
      setLoading(false)
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
    
  }

  const lazyLoad = () => {
    const containerHeight = containerRef?.current?.clientHeight;
    if(scrollPosition + 2200 >= containerHeight){
      setSliceCount(sliceCount+20)
    }
  }
  
  const searchCategoryHandler = (item) => { 
    setSearchData(null)
    setSearchData(item?.shows)
    setActiveCategory(item?.category_name)
    
  }

  if(loading){
    return(
      <Loading/>
    )
  }

  return (
    <div className="searchPage" ref={containerRef}>
      <ToastContainer />
      <div className="wrapper">
        <div className="top">
          <h1 className="heading">Results for</h1>
          <p className="desc">{searchInput}</p>
        </div>
        <div className="bottom">
        <div className="categories">
            {
              searchDataCategories?.map((item,index)=>(
                <button className={activeCategory === item?.category_name ? "active":"categoryButton"} key={index} onClick={()=>searchCategoryHandler(item)} >{item?.category_name}</button>
              ))
            }
          </div>
          <div className="itemsContainer">
            {searchData?.length <= 0 && (
              <h1
                style={{
                  color: "#E72A31",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                No data found
              </h1>
            )}
        {searchData?.slice(0, sliceCount)?.map((item, index) => (
          item?.type === "SHORTS" ? (
            <div
              className={"shortsContainer" }
              key={index} 
            >
               <ShortsCard 
                 data={item}
                 season={false}
                 metaData={true}
                 type={item?.type}
                 vanityUrl={item?.vanity_url}
                      />
            </div>
          ) : (
            <div
              className={
                (thumbnailOrientation || projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION) === "PORTRAIT"
                  ? `items portrait ${rowItemsClass}`
                  : `items landscape ${rowItemsClass}`
              }
              key={index}
            >
              <ShowCard
                data={item}
                imageUrl={true}
                season={false}
                metaData={true}
                modalNavigation={true}
                thumbnailOrientation={thumbnailOrientation}
              />
            </div>
          )
        ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
