import { createSlice } from "@reduxjs/toolkit";

export const scheduleModalSlice=createSlice({
    name:"scheduleModal",
    initialState:{
        value:{
            isOpen:false,
        }
    },
    reducers:{
        getScheduleModal:(state,action)=>{
            state.value=action?.payload?.scheduleModal
        }
    }
})

export const {getScheduleModal}=scheduleModalSlice.actions
export default scheduleModalSlice.reducer